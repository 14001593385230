* {
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
}

:root {
    --black: #333333 !important;
    --gray-2: #4f4f4f !important;
    --gray-3: #828282 !important;
    --gray-4: #bdbdbd !important;
    --gray-5: #e0e0e0 !important;
    --gray-6: #f2f2f2 !important;
    --white: #fafafa !important;
    --green: #28733d !important;
    --green-2: #2f8848 !important;
    --green-3: #43bf66 !important;
    --green-4: #7ee69b !important;
    --blue: #115d8c !important;
    --color-primary: #115d8c !important;
    --blue-2: #2d9cdb !important;
    --blue-3: #56ccf2 !important;
    --purple: #9b51e0 !important;
    --purple-2: #bb6bd9 !important;
    --red: #eb5757 !important;
    --orange: #f2994a !important;
    --yellow: #f2c94c !important;
    --font-title: Calibri, sans-serif;
    --font-content: Calibri, sans-serif;
    --shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
}

html,
body {
    font-size: 70% !important;
}

@media (max-width: 990px) {
    html,
    body {
        font-size: 50% !important;
    }
}

#root {
    font-size: 2rem;
    font-family: var(--font-content);
    color: var(--gray-2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

html,
body,
main,
#root {
    min-height: 100vh;
    width: 100%;
    background-color: var(--gray-6) !important;
}

h1,
h2,
h3,
h4 {
    font-family: var(--font-title);
    color: var(--blue);
    margin-bottom: 1rem !important;
    margin-top: 2rem !important;
}

h1 {
    font-size: 4rem !important;
}

h2 {
    font-size: 3rem !important;
}

h3 {
    font-size: 2rem !important;
}

h4 {
    font-size: 1.5rem !important;
}

p {
    margin: 2rem !important;
}

ul {
    margin: 2rem !important;
}

ol {
    margin: 2rem !important;
}

li {
    margin: 2rem !important;
}

.add {
    font-size: 3rem;
    color: var(--blue);
    display: flex;
    align-items: center !important;
    margin-right: 1rem !important;
}

.add:hover {
    color: var(--blue);
    filter: brightness(120%);
}

.grid-add {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1.5rem !important;
    width: 100%;
}

.grid-add button {
    margin-right: 10px !important;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}

/** --------------------------------------------------------------------------------------------------------------------------
                                                Notifications
 -------------------------------------------------------------------------------------------------------------------------- */

.notification-container .title {
    color: var(--white);
}

.notification-container .message {
    font-size: 1.5rem;
}

.notification-container .message,
.notification-container .title {
    margin-left: 60px !important;
    color: var(--white);
}

.notification {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px !important;
}

/** --------------------------------------------------------------------------------------------------------------------------
                                                DataTables
 -------------------------------------------------------------------------------------------------------------------------- */

.dataTables_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.dataTables_length,
.dataTables_filter,
.dataTables_info {
    width: 50%;
    margin-bottom: 1rem !important;
}

.dataTables_length select {
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: var(--white);
    padding: 10px !important;
    margin-right: 1rem !important;
}

.dataTables_filter input {
    border: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: var(--white);
    padding: 10px !important;
    margin-left: 1rem !important;
}

.dataTables_filter {
    display: flex;
    flex-direction: row-reverse;
}

.table {
    width: 100% !important;
}

.dataTables_paginate,
.dataTables_info {
    margin-top: 1rem !important;
}

.dataTables_paginate {
    margin-left: auto !important;
    margin-right: 2rem !important;
    display: flex;
    align-items: center;
}

.dataTables_paginate span {
    height: 100% !important;
    display: flex;
}

.paginate_button {
    cursor: pointer;
}

.paginate_button.previous,
.paginate_button.next {
    height: 100% !important;
    display: flex;
    align-items: center;
    padding: 0 1rem !important;
    background-color: var(--white);
    border: 1px solid var(--gray-4);
    color: var(--gray-3);
}

.paginate_button.disabled {
    background-color: var(--gray-5);
}

.dataTables_paginate span a {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem !important;
    border-top: 1px solid var(--gray-4);
    color: var(--gray-3);
    border-bottom: 1px solid var(--gray-4);
}

.paginate_button:hover {
    text-decoration: none;
    color: var(--gray-2);
}

.paginate_button.current {
    background-color: var(--blue-2);
    color: var(--white);
}

@media (max-width: 750px) {
    .dataTables_paginate,
    .dataTables_length,
    .dataTables_filter,
    .dataTables_info {
        width: 100%;
    }

    .dataTables_filter {
        flex-direction: row;
    }
}

/** --------------------------------------------------------------------------------------------------------------------------
                                                Confirm Alert
 -------------------------------------------------------------------------------------------------------------------------- */

.react-confirm-alert-body {
    font-size: 2rem !important;
    padding: 2rem !important;
}

.react-confirm-alert-body h1 {
    font-size: 4rem !important;
}

.react-confirm-alert-button-group button {
    font-family: var(--font-title);
    font-weight: 600;
    font-size: 2.3rem !important;
    padding: 4px 20px !important;
    margin: 1rem 1.5rem !important;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: var(--shadow);
}

/** --------------------------------------------------------------------------------------------------------------------------
                                                Navbar
 -------------------------------------------------------------------------------------------------------------------------- */

#responsive-navbar-nav a {
    font-size: 1.5rem;
}

.navbar-public-logo {
    width: 12%;
}

@media (max-width: 750px) {
    .navbar-public-logo {
        width: 30% !important;
    }
}

/** --------------------------------------------------------------------------------------------------------------------------
                                                Select
 -------------------------------------------------------------------------------------------------------------------------- */

.select__multi-value {
    margin: 0 5px !important;
}

.select__multi-value__label {
    padding: 0 5px !important;
}

.select__multi-value__remove {
    padding: 0 5px !important;
}

.slick-slider,
.slick-list {
    width: 100%;
    position: relative;
}

.slick-next,
.slick-prev {
    position: absolute;
    z-index: 100;
}

.slick-next:before,
.slick-prev:before {
    color: var(--black) !important;
}

.slick-next {
    right: 50px !important;
}

.slick-prev {
    left: 50px !important;
}

.slick-dots {
    margin: 1rem 0 !important;
    width: 100%;
}

/** -------------------------------------------------------------------------------------------------------------- 
                                            Draft
 -------------------------------------------------------------------------------------------------------------- */

.rdw-editor-wrapper {
    margin: 1rem !important;
}

.rdw-editor-main {
    border: 1px solid var(--gray-4);
    padding: 2rem !important;
}

.public-DraftEditor-content {
    min-height: 400px;
}

.rdw-editor-toolbar {
    display: flex;
    justify-content: space-between !important;

    padding: 1rem !important;
}

.rdw-dropdown-optionwrapper {
    margin: 0 !important;
}

.rdw-fontfamily-wrapper {
    display: none !important;
}

.place-card {
    display: none !important;
}

.viewer-layout-main {
    overflow: auto;
}
